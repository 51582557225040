import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick"; // Import react-slick slider
import "slick-carousel/slick/slick.css"; // Slick slider styles
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/slider.css"; // Custom styles

// Button click for avant-premiere
const handleBannerLink = (e) => {
  window.open(e.target.value);
};

// Render the sliders one by one in response to API data
const renderPromosSliders = (props) => {
  const { bannieresPromos, cinemaId } = props;
  try {
    if (Array.isArray(bannieresPromos[cinemaId])) {
      const hasImages = bannieresPromos[cinemaId].some(
        (banniere) => banniere.banniere_image_seule.length > 0 || banniere.affiche.length > 0
      );

      return bannieresPromos[cinemaId].map((banniere) => {
        return (
          <div key={banniere.id}>
            {banniere.banniere_image_seule.length > 0 ? (
              <div className="slide-item" style={{ paddingTop: "0px", paddingBottom: "20px" }}>
                {banniere.lien_promo.length > 0 ? (
                  <a href={banniere.lien_promo}>
                    <img
                      className="banner-image-seule"
                      src={"https://cloud.cinevauban.fr/files/images/" + banniere.banniere_image_seule}
                      alt=""
                    />
                  </a>
                ) : (
                  <img
                    className="banner-image-seule"
                    src={"https://cloud.cinevauban.fr/files/images/" + banniere.banniere_image_seule}
                    alt=""
                  />
                )}
              </div>
            ) : (
              <div className="slide-item">
                <div className="film-description">
                  <div className="mb20">
                    <h4 className="mb10">{banniere.type_promo}</h4>
                    <h2 className="large">{banniere.titre}</h2>
                    <p className="mb5" style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: banniere.description }}></p>
                  </div>
                  <h3>{banniere.date_promo}</h3>
                  {banniere.lien_promo.length > 0 && (
                    <button
                      className="btn btn-block btn-danger"
                      data-toggle="modal"
                      data-target="#main-modal"
                      value={banniere.lien_promo}
                      onClick={handleBannerLink}
                    >
                      Réserver
                    </button>
                  )}
                </div>
                <div className="film-image">
                  {banniere.affiche.length > 0 ? (
                    <img src={"https://cloud.cinevauban.fr/files/images/" + banniere.affiche} alt="" />
                  ) : (
                    <i
                      className="fas fa-photo-video"
                      style={{
                        fontSize: "100px",
                        position: "absolute",
                        display: "block",
                        zIndex: "3",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "lightgrey",
                      }}
                    ></i>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      });
    }
  } catch (error) {
    // Handle error
  }
};

// The main PromosSlider function using React Slick
const PromosSlider = (props) => {
  const { bannieresPromos, cinemaId } = props;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Enables navigation arrows
    autoplay: true,
  };

  try {
    if (Array.isArray(bannieresPromos[cinemaId]) && bannieresPromos[cinemaId][0].result === undefined) {
      return (
        <div className="slider-section">
          <Slider {...settings}>{renderPromosSliders(props)}</Slider>
        </div>
      );
    } else {
      return <div></div>; // case where there is no banner for a cinema
    }
  } catch (error) {
    // Handle error
    return (
      <div
        style={{
          height: "150px",
          backgroundColor: "#f0f0f0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );
  }
};

// Connection to Redux store
function mapStateToProps({ dataCines }) {
  return {
    cinemaId: dataCines.cinemaId,
    bannieresPromos: dataCines.bannieresPromos,
  };
}

export default connect(mapStateToProps)(PromosSlider);
