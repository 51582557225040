import React, { Component, Fragment } from "react";
import PromosSwiper from "../PromosSwiper";
import ProgrammeDetails from "../ProgrammeDetails";

class Programme extends Component {
  render() {
    return (
      <Fragment>
        <PromosSwiper visibleSlides="1" totalSlides="3" step="1" />
        <ProgrammeDetails />
        {/*<Subscribe />*/}
      </Fragment>
    );
  }
}

export default Programme;
