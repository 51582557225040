import React, { useState, Fragment } from "react";
import * as api from "../../api";

export default function Contact() {
  const [messageSent, setMessageSent] = useState(0);

  const handleSubmitMessage = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    data.set("objet", "Message formulaire Contact");
    data.set("email_to", "contact@cinevauban.fr");

    const messagePost = api
      .postMessage(data)
      .then(function (response) {
        console.log(response.data);
        if (response.data[0].result) {
          setMessageSent(1);
        } else {
          setMessageSent(2);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log(messagePost);
  };

  return (
    <Fragment>
      <div className="page-title">
        <div className="container">
          <h1>Contact</h1>
        </div>
      </div>
      <div className="white-box inner-section">
        <div className="container">
          <div className="text-center">
            <p className="lh1-7">
              Merci de remplir ce formulaire pour nous adresser un message
              <span className="d-block">Les champs contenant un asterisque sont obligatoires</span>
            </p>
          </div>
        </div>
      </div>
      {messageSent === 2 && (
        <div className="text-center">
          <p className="lh1-7 alert-danger">Il y a eu un problème lors de l'envoi de votre message.</p>
        </div>
      )}

      {messageSent === 1 ? (
        <div className="text-center">
          <p className="lh1-7 alert-danger">Merci. Votre message a bien été expédié nous y répondrons dans les meilleurs délais.</p>
        </div>
      ) : (
        <div className="form-section" onSubmit={handleSubmitMessage}>
          <div className="container">
            <div className="user-form-wrap">
              <form action="" method="">
                <div className="row row-user">
                  <div className="col-12 col-md-6 col-user">
                    <div className="mb15">
                      <input
                        className="form-control form-control-small"
                        type="n umber"
                        placeholder="Prénom Nom*"
                        name="prenom_nom"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-user">
                    <div className="mb15">
                      <input className="form-control form-control-small" type="text" placeholder="Ville" name="ville" />
                    </div>
                  </div>
                </div>
                <div className="row row-user">
                  <div className="col-12 col-md-6 col-user">
                    <div className="mb15">
                      <input className="form-control form-control-small" type="mail" placeholder="Email*" name="email" required />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-user">
                    <div className="mb15">
                      <input className="form-control form-control-small" type="text" placeholder="Téléphone" name="telephone" />
                    </div>
                  </div>
                </div>
                <div className="mb15">
                  <textarea className="form-control form-control-small" placeholder="Votre message" name="message"></textarea>
                </div>
                <div className="mb0">
                  <button className="btn btn-block btn-danger">ENVOYER</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
